import { ReactNode } from "react";
import UI from "./UI";

interface ICheckbox {
  label: ReactNode;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  validate?: boolean;
  footerCheckbox?: boolean;
  footerCheckboxLabel?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const Checkbox = ({
  label,
  checked,
  onChange,
  isValid,
  validate,
  footerCheckbox,
  errorMessage,
  disabled
}: ICheckbox) => {
  return (
    <UI
      label={label}
      checked={checked}
      onChange={onChange}
      isValid={isValid}
      validate={validate}
      footerCheckbox={footerCheckbox}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  );
};

export default Checkbox;
