"use client";

import ArrowRight from "@/components/icons/ArrowRight";
import Button from "@/components/ui/Button";
import { RichText } from "@/components/ui/RichText/RichText";
import env from "@/env.mjs";
import Checkbox from "@/features/checkout/Checkbox/Checkbox";
import { useBoolean } from "@/hooks/useBoolean";
import { useSelection } from "@/lib/centra/hooks/useSelection";
import getLink from "@/lib/storyblok/utils/getLink";
import { FooterStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ChangeEvent, useEffect, useState } from "react";
import styles from "./index.module.css";

interface INewsletterSection {
  newsletterTitle?: string;
  newsletterText?: string;
  privacyPolicyLink?: FooterStoryblok["privacyPolicyLink"];
}

const NewsletterSection = ({
  newsletterTitle,
  newsletterText,
  privacyPolicyLink,
}: INewsletterSection) => {
  const [value, setValue] = useState("");
  const [state, setState] = useState("idle");
  const path = usePathname();
  const checkboxState = useBoolean(false);
  const link = getLink(privacyPolicyLink);
  const t = useTranslations();
  const { loggedIn } = useSelection();
  const isSubscribed = loggedIn?.newsletter;

  useEffect(() => {
    setValue("");
    setState("idle");
  }, [path]);

  const toggleCheckboxState = (e: ChangeEvent<HTMLInputElement>) => {
    checkboxState.toggle();
    setState("");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const base = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

    if (!checkboxState.value) {
      setState("checkboxNotChecked");
      return;
    }

    setState("fetching");
    const res = await fetch(`${base}/newsletter-subscription/${value}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email_field: value,
      }),
    });
    if (res.ok) {
      setState("success");
      return;
    } else {
      setState("error");
      return;
    }
  };

  return (
    <div className={styles.newsletter}>
      <div>
        <div className={styles.newsletter__inner}>
          {newsletterTitle && (
            <h2 className={styles.newsletter__title}>{newsletterTitle}</h2>
          )}
          {newsletterText && (
            <div>
              <RichText>{newsletterText}</RichText>
            </div>
          )}

          {state !== "success" && (
            <form onSubmit={handleSubmit} className={styles.form}>
              {isSubscribed ? (
                <span>
                  <Checkbox
                    footerCheckbox={true}
                    checked={true}
                    onChange={toggleCheckboxState}
                    disabled
                    isValid={state != "checkboxNotChecked"}
                    validate={true}
                    errorMessage={t("footer.newsletter.checkboxNotChecked")}
                    label={t.rich("footer.newsletter.signedUp", {
                      link: (chunks) => <Link href={link}>{chunks}</Link>,
                    })}
                  />
                </span>
              ) : (
                <>
                  <div className={styles.inputWrap}>
                    <input
                      type="email"
                      className={`${styles.newsletter__input}`}
                      value={value}
                      placeholder="Your e-mail"
                      required
                      onChange={(e) => setValue(e.target.value)}
                    />

                    <div className={styles.submit}>
                      <Button mode="invert" aria-label="Submit">
                        <ArrowRight />
                      </Button>
                    </div>
                  </div>

                  <span>
                    <Checkbox
                      footerCheckbox={true}
                      checked={checkboxState.value}
                      onChange={toggleCheckboxState}
                      isValid={state != "checkboxNotChecked"}
                      validate={true}
                      errorMessage={t("footer.newsletter.checkboxNotChecked")}
                      label={t.rich("footer.newsletter.accept", {
                        link: (chunks) => <Link href={link}>{chunks}</Link>,
                      })}
                    />
                  </span>
                </>
              )}
            </form>
          )}
        </div>

        {state === "success" && (
          <p className={clsx(styles.message, styles.success)}>
            {t("footer.newsletter.success")}
          </p>
        )}

        {state === "error" && (
          <p className={styles.message}>{t("footer.newsletter.error")}</p>
        )}
      </div>
    </div>
  );
};
export default NewsletterSection;
