import clsx from "clsx";
import { useTranslations } from "next-intl";
import { ReactNode } from "react";
import styles from "./checkbox.module.css";
const UI = ({
  label,
  checked,
  onChange,
  isValid,
  validate,
  footerCheckbox,
  errorMessage,
  disabled
}: {
  label: ReactNode;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  validate?: boolean;
  footerCheckbox?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}) => {
  const t = useTranslations();
  return (
    <>
      <label
        className={
          footerCheckbox ? styles.footerCheckboxLabel : styles.checkbox
        }
      >
        <input
          type="checkbox"
          checked={checked}
          required={validate}
          onChange={onChange}
          disabled={disabled}
        />
        <span
          className={clsx(
            footerCheckbox ? styles.footerFauxInput : styles.fauxInput,
            checked && styles.checked,
            disabled && styles.disabled
          )}
        ></span>
        <span>{label}</span>
        {isValid || !validate ? null : (
          <p className={styles.error}>
            {errorMessage ? errorMessage : "This is required"}
          </p>
        )}
      </label>
    </>
  );
};

export default UI;
